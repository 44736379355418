import { getSliceGroqQuery } from '@livewire/website/lib/getSliceGroqQuery';

const flexibleArticleGroq = getSliceGroqQuery(`
  media {
    media {
      caption,
      subcaption,
      image {
        altText,
        asset-> {
          url
        },
      },
      
      video {
        url
      }
    }  
  },

  mediaAlignment,
  subheading,
  heading,
  body,

  buttons {
    buttons[] {
      ...,
      link[] {
        ...,
        page -> {
          slug
        }
      }
    }
  }
`);

export default flexibleArticleGroq;
