import { getSliceGroqQuery } from '@livewire/website/lib/getSliceGroqQuery';

const richTextGroq = getSliceGroqQuery(`
  text[] {
    ...,
    asset -> {
      url
    }
  }
`);

export default richTextGroq;
