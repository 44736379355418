import React, { useState } from 'react';
import cn from 'classnames';
import { IVideoUrl } from '@livewire/sanity';
import { SVG } from '@livewire/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: IVideoUrl;
  className: string;
}

const AudioVideo = ({ data, className }: IProps) => {
  const { url, enableVideoAudio } = data || {};

  const [isMuted, setIsMuted] = useState(true);

  const toggleAudio = () => {
    setIsMuted((current) => !current);
  };

  return (
    <div className={cn(styles.container, className)}>
      <video className={styles.video} autoPlay muted={isMuted} loop playsInline>
        <source src={url} type="video/mp4" />
      </video>

      {enableVideoAudio && (
        <button
          className={styles.soundBtn}
          type="button"
          onClick={() => toggleAudio()}
        >
          <figure className={styles.svg}>
            <SVG svg={isMuted ? 'soundOn' : 'soundOff'} />
          </figure>
        </button>
      )}
    </div>
  );
};

export default AudioVideo;
