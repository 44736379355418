import { getSliceGroqQuery } from '@livewire/website/lib/getSliceGroqQuery';

const leadCaptureGroq = getSliceGroqQuery(`
  headingStyle,
  heading,
  body,
  confirmationHeading,
  confirmationMessage,
`);

export default leadCaptureGroq;
