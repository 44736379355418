import { getSliceGroqQuery } from '@livewire/website/lib/getSliceGroqQuery';

const articleGroq = getSliceGroqQuery(`
  link {
    _type,
    url,
    page -> {
      slug {
        current
      }
    }
  },

  textAlign,
  _rawText,

  media {
    image {
      altText,
      asset -> {
        url
      },
    },

    video {
      url
    }
  }
`);

export default articleGroq;
