import { getSliceGroqQuery } from '@livewire/website/lib/getSliceGroqQuery';

const featureGroq = getSliceGroqQuery(`
  blocks[] {
    tags,
    body,
    headingStyle,
    heading,
    subheading,
      
    media {
      image {
        altText,
        asset-> {
          url
        },
      },

      video {
        url
      }
    },

    buttons {
      buttons[] {
        ...,
        link[] {
          ...,
          page -> {
            slug
          }
        }
      }
    }
  }
`);

export default featureGroq;
