import { getSliceGroqQuery } from '@livewire/website/lib/getSliceGroqQuery';

const cardGridGroq = getSliceGroqQuery(`
  headingStyle,
  heading,
  mobileCarousel,

  cards[] {
    _type,
    heading,
    subheading,
    description,

    media {
      image {
        altText,
        asset-> {
          url
        },
      },
      
      video {
        url
      }
    }
  }
`);

export default cardGridGroq;
