import React from 'react';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { Button, SVG } from '@livewire/common/components';
import { IRichTextSocialShare, IFooter } from '@livewire/sanity';

import * as styles from './styles.module.scss';

export interface IProps {
  data: IRichTextSocialShare;
}

const PortableTextSocialShare = ({ data }: IProps) => {
  const settings: IFooter = useStaticQuery(graphql`
    query {
      sanitySettings {
        footer {
          legal
          copyright

          socialLinks {
            text
            url
            _key
          }

          internalLinks {
            title
            slug {
              current
            }
            _id
          }
        }
      }
    }
  `)?.sanitySettings?.footer?.socialLinks;

  const { heading } = data || {};

  return (
    <div className={styles.container}>
      {heading && <p className={cn('b1', styles.heading)}>{heading}</p>}
      {settings?.[0] && (
        <div className={styles.socialLinks}>
          {settings.map((socialLink) => {
            const { _key, text, url } = socialLink || {};

            return (
              <div
                key={`rich-text-social-link-${_key}`}
                className={styles.button}
              >
                <Button href={url}>
                  <figure className={styles.icon}>
                    <SVG svg={text.toLowerCase()} />
                  </figure>
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PortableTextSocialShare;
