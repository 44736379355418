import React from 'react';
import cn from 'classnames';
import { UniversalLink } from '@livewire/common/components';
import { IRichTextButton } from '@livewire/sanity';

import * as styles from './styles.module.scss';

export interface IProps {
  data: IRichTextButton;
}

const PortableTextButton = ({ data }: IProps) => {
  const { link, variant } = data || {};

  if (!link) return null;

  return (
    <div className={cn('rich-text-button', styles.container)}>
      <UniversalLink
        link={link}
        variant={variant || 'primary'}
        theme="lightOnDark"
      />
    </div>
  );
};

export default PortableTextButton;
