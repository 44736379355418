import React from 'react';
import cn from 'classnames';
import { SVG } from '@livewire/common/components';
import { IRichTextBlockQuote } from '@livewire/sanity';

import * as styles from './styles.module.scss';

export interface IProps {
  data: IRichTextBlockQuote;
}

const PortableTextBlockQuote = ({ data }: IProps) => {
  const { quote, source } = data || {};

  if (!quote) return null;

  return (
    <div className={styles.container}>
      <figure className={styles.icon}>
        <SVG svg="quotationMark" />
      </figure>
      <h4 className={cn('h4', styles.quote)}>{quote}</h4>
      {source && <p className="caption">— {source}</p>}
    </div>
  );
};

export default PortableTextBlockQuote;
