import React from 'react';
import { SVG, TSvg } from '@livewire/common/components';
import cn from 'classnames';
import { Link } from 'gatsby';
import { TThemeColor } from '@livewire/sanity';
import { useBreakpoints } from '@livewire/common/hooks';
import * as styles from './styles.module.scss';

export type TButtonVariant =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'textBig'
  | 'tab';

export interface IButton {
  // Appearance
  children: React.ReactNode;
  variant?: TButtonVariant;
  theme?: 'lightOnDark' | 'darkOnLight' | 'white';
  color?: TThemeColor;
  iconLeft?: TSvg;
  iconRight?: TSvg;
  disabled?: boolean;
  fluid?: boolean;
  loading?: boolean;
  // Functionality
  href?: string;
  to?: string;
  buttonType?: 'button' | 'reset' | 'submit';
  display?: boolean;
  onClick?: () => void;
  className?: string;
}

const Button = ({
  children,
  variant = 'primary',
  buttonType = 'button',
  theme = 'darkOnLight',
  color = 'sea',
  to,
  href,
  iconLeft,
  iconRight,
  disabled,
  fluid,
  loading,
  display,
  onClick,
  className
}: IButton) => {
  let BtnElement: any;
  if (display) BtnElement = 'div';
  else if (to) BtnElement = Link;
  else if (href) BtnElement = 'a';
  else BtnElement = 'button';

  const { largeTablet } = useBreakpoints();

  const tabClass = largeTablet ? 'b3' : 'h6';

  return (
    <BtnElement
      type={to || href ? '' : buttonType}
      href={href}
      target={href ? '_blank' : null}
      rel={href ? 'noreferrer noopener' : null}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? '-1' : 0}
      to={to}
      className={cn(
        'button',
        styles.button,
        className,
        styles[variant],
        styles[color],
        {
          [styles.fluid]: fluid,
          [styles.loading]: loading,
          [styles.inverted]: theme === 'lightOnDark',
          [styles.white]: theme === `white`
        }
      )}
    >
      <div className={styles.content}>
        {iconLeft && <SVG svg={iconLeft} className={styles.icon} />}

        <span
          className={`button-${variant === 'textBig' ? 'big' : 'small'} ${
            variant === 'tab' ? `button-tab ${tabClass}` : ''
          }`}
        >
          {variant === 'tab' && (
            <figure className={styles.squareSvg}>
              <SVG svg="square" />
            </figure>
          )}
          {children}
        </span>

        {iconRight && <SVG svg={iconRight} className={styles.icon} />}
      </div>

      {!display && (
        <div className={styles.loaderContainer}>
          <div className={styles.loader} />
        </div>
      )}
    </BtnElement>
  );
};

export default Button;
