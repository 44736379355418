import { getSliceGroqQuery } from '@livewire/website/lib/getSliceGroqQuery';

const articleGridGroq = getSliceGroqQuery(`
  headingStyle,
  heading,
  mobileCarousel,
  
  showAllCaseStudies,
  showAllNewsArticles,
  showAll,

  articles[] {
    _type,
    title,

    slug {
      current
    },
    
    publishDate,
    excerpt,

    cardImage {
      image {
        asset {
          url
        }
      },
      video {
        url
      }
    }
  }
`);

export default articleGridGroq;
