// extracted by mini-css-extract-plugin
export var button = "styles-module--button--cf675";
export var content = "styles-module--content--5e14b";
export var desktop = "1340px";
export var fluid = "styles-module--fluid--cc6cc";
export var giant = "2200px";
export var icon = "styles-module--icon--02218";
export var inverted = "styles-module--inverted--c8f83";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var loader = "styles-module--loader--ccdfe";
export var loaderContainer = "styles-module--loaderContainer--180ea";
export var loading = "styles-module--loading--c75de";
export var mobile = "400px";
export var primary = "styles-module--primary--3470b";
export var secondary = "styles-module--secondary--5e0e9";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var spin = "styles-module--spin--bd9eb";
export var squareSvg = "styles-module--squareSvg--b7574";
export var tab = "styles-module--tab--0d49e";
export var tablet = "768px";
export var text = "styles-module--text--14a3d";
export var textBig = "styles-module--textBig--c750a";
export var white = "styles-module--white--595fd";