// todo - can we delete the Grid, MaxWidth and Grid components?

import React from 'react';
import cn from 'classnames';
import { Grid } from '@livewire/common/components';

import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  grid?: boolean;
  gridClassName?: string;
  padding?: boolean;
}

const LayoutProvider = ({
  children,
  className,
  grid,
  gridClassName,
  padding
}: IProps) => {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.padding]: padding
      })}
    >
      {grid ? <Grid className={gridClassName}>{children}</Grid> : children}
    </div>
  );
};

export default LayoutProvider;
