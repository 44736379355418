import React from 'react';
import { IRichTextEmbed } from '@livewire/sanity';

import * as styles from './styles.module.scss';

export interface IProps {
  data: IRichTextEmbed;
}

const PortableTextEmbed = ({ data }: IProps) => {
  const { code } = data || {};

  if (!code) return null;

  return (
    <div className={styles.mediaContainer}>
      <div dangerouslySetInnerHTML={{ __html: code }} />
    </div>
  );
};

export default PortableTextEmbed;
