import React from 'react';
import { PortableText } from '@portabletext/react';
import cn from 'classnames';
import {
  IRichTextImage,
  IRichTextVideo,
  IRichTextEmbed,
  IRichTextButton,
  IRichTextBlockQuote,
  IRichTextSocialShare
} from '@livewire/sanity';
import {
  PortableTextBlockQuote,
  PortableTextButton,
  PortableTextEmbed,
  PortableTextImage,
  PortableTextSocialShare,
  PortableTextVideo
} from '@livewire/common/components';
import * as styles from './styles.module.scss';

export interface ISanityConfig {
  projectId: string;
  dataset: string;
}

interface ICustomComponentsConfig {
  gatsbyImageComponent: React.FunctionComponent<any>; // import { GatsbyImage } from 'gatsby-plugin-image';
  getGatsbyImageData: (imageId: string, {}, sanityConfig: ISanityConfig) => any; // import { getGatsbyImageData } from 'gatsby-source-sanity';
  textClassName?: string;
}

interface IProps extends ICustomComponentsConfig {
  containerClassName?: string;
  rawText: any;
}

interface IRichTextButtonProps {
  value: IRichTextButton;
}

interface IRichTextImageProps {
  value: IRichTextImage;
}

interface IRichTextVideoProps {
  value: IRichTextVideo;
}

interface IRichTextEmbedProps {
  value: IRichTextEmbed;
}

interface IRichTextBlockQuoteProps {
  value: IRichTextBlockQuote;
}

interface IRichTextBlockQuoteProps {
  value: IRichTextBlockQuote;
}

interface IRichTextSocialShareProps {
  value: IRichTextSocialShare;
}

const customComponents = ({
  gatsbyImageComponent,
  getGatsbyImageData,
  textClassName
}: ICustomComponentsConfig) => ({
  listItem: {
    number: ({ children }: any) => {
      const nodeStyle = children?.props?.node?.style || textClassName || 'b2';

      return (
        <li className={cn(styles.orderedListItem, nodeStyle)}>{children}</li>
      );
    },
    bullet: ({ children }: any) => {
      const nodeStyle = children.props?.node.style || 'b1';
      return (
        <li className={cn(styles.unorderedListItem, nodeStyle)}>{children}</li>
      );
    }
  },
  block: {
    normal: ({ children }: any) => <p>{children}</p>,
    d1: ({ children }: any) => <h2 className="d1">{children}</h2>,
    ['d1-expanded']: ({ children }: any) => (
      <h2 className="d1-expanded">{children}</h2>
    ),
    ['d1-extended']: ({ children }: any) => (
      <h2 className="d1-extended">{children}</h2>
    ),
    ['d1-comp']: ({ children }: any) => <h2 className="d1-comp">{children}</h2>,
    ['d1-standard']: ({ children }: any) => (
      <h2 className="d1-standard">{children}</h2>
    ),
    ['d1-x-comp']: ({ children }: any) => (
      <h2 className="d1-x-comp">{children}</h2>
    ),
    d2: ({ children }: any) => <h2 className="d2">{children}</h2>,
    d3: ({ children }: any) => <h2 className="d3">{children}</h2>,
    h1: ({ children }: any) => <h2 className="h1">{children}</h2>,
    h2: ({ children }: any) => <h3 className="h2">{children}</h3>,
    h3: ({ children }: any) => <h4 className="h3">{children}</h4>,
    h4: ({ children }: any) => <h4 className="h4">{children}</h4>,
    h5: ({ children }: any) => <h5 className="h5">{children}</h5>,
    h6: ({ children }: any) => <h6 className="h6">{children}</h6>,
    b1: ({ children }: any) => <p className="b1">{children}</p>,
    b2: ({ children }: any) => <p className="b2">{children}</p>,
    caption: ({ children }: any) => <p className="caption">{children}</p>,
    ['button-text']: ({ children }: any) => (
      <p className="button-text">{children}</p>
    )
  },
  marks: {
    color: ({ children, value }: any) => {
      const hex = value?.color?.hex;
      const colorStyle = {
        color: hex
      };
      return <span style={colorStyle}>{children}</span>;
    },
    link: ({ children, value }: any) => {
      const rel = value?.href?.startsWith('/')
        ? undefined
        : 'noreferrer noopener';
      return (
        <a style={{ textDecoration: `underline` }} href={value.href} rel={rel}>
          {children}
        </a>
      );
    }
  },
  types: {
    richTextImage: ({ value }: IRichTextImageProps) => (
      <PortableTextImage
        gatsbyImageComponent={gatsbyImageComponent}
        getGatsbyImageData={getGatsbyImageData}
        data={value}
      />
    ),
    richTextVideo: ({ value }: IRichTextVideoProps) => (
      <PortableTextVideo data={value} />
    ),
    richTextBlockQuote: ({ value }: IRichTextBlockQuoteProps) => (
      <PortableTextBlockQuote data={value} />
    ),
    richTextButton: ({ value }: IRichTextButtonProps) => (
      <PortableTextButton data={value} />
    ),
    richTextEmbed: ({ value }: IRichTextEmbedProps) => (
      <PortableTextEmbed data={value} />
    ),
    richTextSocialShare: ({ value }: IRichTextSocialShareProps) => (
      <PortableTextSocialShare data={value} />
    )
  }
});

const PortableTextRenderer = ({
  rawText,
  containerClassName,
  gatsbyImageComponent,
  getGatsbyImageData,
  textClassName
}: IProps) => {
  return (
    <div className={cn(styles.container, containerClassName)}>
      <PortableText
        value={rawText}
        components={customComponents({
          gatsbyImageComponent,
          getGatsbyImageData,
          textClassName
        })}
      />
    </div>
  );
};

export default PortableTextRenderer;
